:global(#app) {
  .action {
    border: none;
    border-radius: 0.28571429rem;
    display: inline-block;
    height: 36px;
    overflow: hidden;
    position: relative;
    transition: background 0.3s ease;
    width: 100%;

    &:hover {
      background: #e9e9e9;
    }
  }

  .actions {
    margin-top: 20px;
  }

  .actionButton {
    background: transparent;
    color: #6b808c;
    font-weight: normal;
    height: 36px;
    line-height: 24px;
    padding: 6px 12px;
    text-align: left;
    text-decoration: underline;
    width: 100%;
  }

  .gradientButton {
    float: left;
    height: 40px;
    margin: 4px;
    padding: 0;
    position: relative;
    width: 49.6px;

    &:hover {
      opacity: 0.9;
    }
  }

  .gradientButtonActive:before {
    bottom: 3px;
    color: #ffffff;
    content: "Г";
    font-size: 18px;
    line-height: 36px;
    position: absolute;
    right: 6px;
    text-align: center;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    top: 0;
    transform: rotate(-135deg);
    width: 36px;
  }

  .gradientButtons {
    margin-left: -4px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .image {
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      opacity: 0.9;
    }
  }

  .imageLabel {
    border-color: rgba(29, 46, 63, 0.8);
  }
}
