:global(#app) {
  .action {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-right: 20px;
  }

  .actions {
    align-items: center;
    display: flex;
    margin: 20px 20px;
  }

  .wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
