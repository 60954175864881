:global(#app) {
  .action {
    border: none;
    border-radius: 0.28571429rem;
    display: inline-block;
    height: 36px;
    overflow: hidden;
    position: relative;
    transition: background 0.3s ease;
    width: 100%;

    &:hover {
      background: #e9e9e9;
    }
  }

  .actionButton {
    background: transparent;
    color: #6b808c;
    font-weight: normal;
    height: 36px;
    line-height: 24px;
    padding: 6px 12px;
    text-align: left;
    text-decoration: underline;
    width: 100%;
  }
}
