:global(#app) {
  .message {
    background: #eb5a46;
    border-radius: 4px;
    bottom: 20px;
    box-shadow: #b04632 0 1px 0;
    padding: 12px 18px;
    position: fixed;
    right: 20px;
    width: 390px;
    z-index: 10001;
  }

  .messageContent {
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
  }

  .messageHeader {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 8px;
  }
}
